import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Button, Grid, Card, CardContent, CardActionArea } from '@mui/material';

const CentralHomePage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="background.default"
      p={2}
    >
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', color: '#ffffff' }}>
        G.K.B. Prototype Hub
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Card sx={{ bgcolor: 'background.paper', color: 'text.primary', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
            <CardActionArea component={Link} to="/activity-planner">
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Activity Planner
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Plan fun activities and events for your friends.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        {/* Add more prototypes */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ bgcolor: 'background.paper', color: 'text.primary', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}>
            <CardActionArea component={Link} to="/prototype2">
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Prototype 2
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Description for Prototype 2.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CentralHomePage;
