import React, { useState } from 'react';
import { Box, TextField, Button, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ActivityPlanner = () => {
  const [people, setPeople] = useState(1);
  const [availability, setAvailability] = useState('');
  const [budget, setBudget] = useState('');
  const [preferences, setPreferences] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post('http://localhost:5001/api/get-recommendations', {
        people,
        availability,
        budget,
        preferences,
      });

      const suggestions = response.data.recommendations;
      navigate('/recommendations', { state: { recommendations: suggestions } });
    } catch (err) {
      console.error("API error: ", err);
      setError('Something went wrong, please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#ffffff"
    >
      <Typography variant="h4" gutterBottom>
        Plan Your Activity
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '500px' }}>
        <TextField
          label="Number of people"
          fullWidth
          variant="outlined"
          margin="normal"
          type="number"
          value={people}
          onChange={(e) => setPeople(e.target.value)}
        />
        <TextField
          label="Availability (e.g., Friday 6 PM - 10 PM)"
          fullWidth
          variant="outlined"
          margin="normal"
          value={availability}
          onChange={(e) => setAvailability(e.target.value)}
        />
        <TextField
          label="Budget per person"
          fullWidth
          variant="outlined"
          margin="normal"
          type="number"
          value={budget}
          onChange={(e) => setBudget(e.target.value)}
        />
        <TextField
          label="Activity preference (optional)"
          fullWidth
          variant="outlined"
          margin="normal"
          value={preferences}
          onChange={(e) => setPreferences(e.target.value)}
        />
        <Box mt={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Get Recommendations'}
          </Button>
        </Box>
        {error && (
          <Typography color="error" align="center" mt={2}>
            {error}
          </Typography>
        )}
      </form>
    </Box>
  );
};

export default ActivityPlanner;
