import React from 'react';
import { Box, Typography } from '@mui/material';

const Prototype2 = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Typography variant="h4">Welcome to Islamic Education</Typography>
    </Box>
  );
};

export default Prototype2;
