import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';
import Grid from '@mui/material/Grid2';

const Recommendations = () => {
  const location = useLocation();
  const recommendations = Array.isArray(location.state?.recommendations) ? location.state.recommendations : [];
  const navigate = useNavigate();

  const handleCardClick = (recommendation) => {
    navigate('/details', { state: { recommendation } }); // Navigate to detailed view
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f5f5f5"
    >
      <Typography variant="h4" gutterBottom>
        Your Activity Agendas
      </Typography>
      {recommendations.length > 0 ? (
        <Grid container spacing={4} justifyContent="center">
          {recommendations.map((recommendation, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card style={{ cursor: 'pointer', height: '100%' }} onClick={() => handleCardClick(recommendation)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={`https://via.placeholder.com/400x300?text=Image+${index + 1}`} // Placeholder image for now
                  alt={recommendation.name}
                />
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {recommendation.name}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {recommendation.agenda}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Price: {recommendation.price}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Timings: {recommendation.timings}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No recommendations available.
        </Typography>
      )}
    </Box>
  );
};

export default Recommendations;
