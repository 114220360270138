import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardMedia, Button } from '@mui/material';

const DetailsPage = () => {
  const location = useLocation();
  const recommendation = location.state?.recommendation;

  if (!recommendation) {
    return <Typography variant="h6">No details available.</Typography>;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="#f5f5f5">
      <Card style={{ maxWidth: 600 }}>
        <CardMedia
          component="img"
          height="300"
          image={`https://via.placeholder.com/600x400?text=Detail+Image`} // Placeholder image
          alt={recommendation.name}
        />
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {recommendation.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {recommendation.agenda}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Price: {recommendation.price}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Timings: {recommendation.timings}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Additional details for bookings and reservations can be added here.
          </Typography>
          {/* Mock booking button */}
          <Button variant="contained" color="primary">
            Make a Reservation
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DetailsPage;
