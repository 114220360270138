import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CentralHomePage from './pages/CentralHomePage';
import ActivityPlanner from './apps/activity-planner/ActivityPlanner';
import Recommendations from './apps/activity-planner/Recommendation';
import DetailsPage from './apps/activity-planner/DetailsPage';
import Prototype2 from './apps/prototype2/Prototype2';
import MenuBar from './components/MenuBar';  // Import the MenuBar component
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from './theme';  // Import the dark theme

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <MenuBar />  {/* Menu bar available on all pages */}
        <Routes>
          <Route path="/" element={<CentralHomePage />} />
          <Route path="/activity-planner" element={<ActivityPlanner />} />
          <Route path="/activity-planner/recommendations" element={<Recommendations />} />
          <Route path="/activity-planner/details" element={<DetailsPage />} />
          <Route path="/prototype2" element={<Prototype2 />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
